var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BModal', {
    attrs: {
      "id": "modal-add-multi-fftv",
      "title": _vm.$t('reservation.addBSV'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.showHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('div', {
          staticClass: "d-flex-center gap-2"
        }, [_c('BButton', {
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")]), _c('BButton', {
          attrs: {
            "variant": "info",
            "disabled": _vm.loading
          },
          on: {
            "click": _vm.handleSubmit
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.addBSV')) + " ")])], 1)];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('h3', [_vm._v("Thêm thẻ Bông Sen Vàng")]), _vm.bookingCode ? _c('span', [_vm._v(" Mã đặt chỗ: "), _c('span', {
    staticClass: "font-weight-bolder text-info font-medium-5"
  }, [_vm._v(_vm._s(_vm.bookingCode))])]) : _vm._e(), _c('div', {
    staticClass: "text-warning font-italic my-50"
  }, [_c('div', [_vm._v("(*) Lựa chọn hành khách để thêm thẻ Bông Sen Vàng")]), _c('div', [_vm._v("(**) vui lòng không nhập thẻ khống")]), _c('div', [_vm._v("(***) bạn sẽ chịu trách nhiệm bồi thường cho hãng nếu thông tin sai")])]), _c('div', {
    staticClass: "my-1"
  }, [_c('div', {
    staticClass: "my-1"
  }, _vm._l(_vm.paxData, function (pax) {
    return _c('div', {
      key: pax.paxId,
      staticClass: "mb-1"
    }, [_c('BFormCheckbox', {
      model: {
        value: pax.isCheck,
        callback: function callback($$v) {
          _vm.$set(pax, "isCheck", $$v);
        },
        expression: "pax.isCheck"
      }
    }, [_c('div', {
      staticClass: "font-weight-bolder font-medium-1"
    }, [_vm._v(" " + _vm._s(pax.paxId) + " " + _vm._s(pax.lastName) + " " + _vm._s(pax.firstName) + " " + _vm._s(pax.title) + " ")])]), _c('div', {
      staticClass: "d-flex-center px-2"
    }, [pax.isCheck ? _c('BInputGroup', {
      staticClass: "border rounded",
      attrs: {
        "id": "memberFly-".concat(pax.paxId)
      }
    }, [_c('BInputGroupPrepend', {
      class: _vm.isMobileView ? 'flex-wrap' : '',
      attrs: {
        "id": "memberFly-".concat(pax.paxId)
      }
    }, [_c('v-select', {
      staticClass: "membership-airline-select",
      class: _vm.isMobileView ? 'border-secondary' : '',
      staticStyle: {
        "width": "200px",
        "max-height": "38px"
      },
      attrs: {
        "id": "memberFly-".concat(pax.paxId),
        "options": [],
        "label": "text",
        "disabled": "",
        "clearable": false,
        "placeholder": "".concat(_vm.$t('flight.airline')),
        "reduce": function reduce(val) {
          return val.value;
        }
      },
      scopedSlots: _vm._u([{
        key: "option",
        fn: function fn(data) {
          return [_c('span', {
            staticClass: "font-small-3 my-25 font-weight-bold"
          }, [_vm._v(" " + _vm._s(data.text) + " ")])];
        }
      }, {
        key: "selected-option",
        fn: function fn(data) {
          return [_c('span', {
            staticClass: "font-small-3 font-weight-bolder text-nowrap text-truncate",
            staticStyle: {
              "max-width": "200px"
            }
          }, [_vm._v(" " + _vm._s(data.text) + " ")])];
        }
      }], null, true),
      model: {
        value: pax.fFlyerAirline,
        callback: function callback($$v) {
          _vm.$set(pax, "fFlyerAirline", $$v);
        },
        expression: "pax.fFlyerAirline"
      }
    })], 1), _c('b-form-input', {
      class: _vm.isMobileView ? 'border-secondary' : '',
      staticStyle: {
        "min-width": "145px"
      },
      attrs: {
        "id": "cardNumber-".concat(pax.paxId),
        "type": "number",
        "placeholder": "".concat(_vm.$t('flight.memberFly'))
      },
      model: {
        value: pax.fFlyerNumber,
        callback: function callback($$v) {
          _vm.$set(pax, "fFlyerNumber", $$v);
        },
        expression: "pax.fFlyerNumber"
      }
    })], 1) : _vm._e()], 1)], 1);
  }), 0), _vm.errorMessage ? _c('BAlert', {
    staticClass: "px-2 py-50",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e()], 1), _c('BAlert', {
    staticClass: "px-2 py-50",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Lưu ý: Kiểm tra lại thông tin thẻ Bông Sen Vàng khi thêm thành công hiển thị dưới thông tin hành khách. ")])], 1)], 1), _c('BModal', {
    attrs: {
      "id": "modal-add-multi-fftv-show-response",
      "title": _vm.$t('reservation.addBSV'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-75",
      "centered": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('b-button', {
          staticClass: "rounded-pill px-2",
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": close
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")])];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": !_vm.response
    }
  }, [_vm.response ? _c('div', {
    staticClass: "position-relative"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "position-absolute p-75 rounded-circle m-25",
    staticStyle: {
      "top": "0",
      "right": "10px",
      "z-index": "9999"
    },
    attrs: {
      "variant": "flat-secondary",
      "disabled": _vm.disabledCopy
    },
    on: {
      "click": _vm.handleCopyText
    }
  }, [_c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window",
      modifiers: {
        "hover": true,
        "window": true
      }
    }],
    staticClass: "text-dark",
    attrs: {
      "icon": "CopyIcon",
      "size": "21",
      "title": _vm.$t('copy')
    }
  })], 1), !_vm.isEmpty(_vm.response.booking) ? [_c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif",
      "height": "60vh",
      "overflow": "auto"
    },
    attrs: {
      "value": _vm.response.booking.join('\n'),
      "rows": "16",
      "max-rows": "40"
    }
  })] : _vm._e(), _c('BAlert', {
    staticClass: "px-1 py-50 mt-1",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" Vui lòng kiểm tra lại thông tin hành khách. ")])], 2) : _vm._e()])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }