<template lang="">
  <div>
    <BModal
      id="modal-add-multi-fftv"
      :title="$t('reservation.addBSV')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      size="lg"
      no-close-on-backdrop
      @show="showHandle"
    >
      <IAmOverlay :loading="loading">
        <h3>Thêm thẻ Bông Sen Vàng</h3>
        <span v-if="bookingCode">
          Mã đặt chỗ: <span class="font-weight-bolder text-info font-medium-5">{{ bookingCode }}</span>
        </span>

        <div class="text-warning font-italic my-50">
          <div>(*) Lựa chọn hành khách để thêm thẻ Bông Sen Vàng</div>
          <div>(**) vui lòng không nhập thẻ khống</div>
          <div>(***) bạn sẽ chịu trách nhiệm bồi thường cho hãng nếu thông tin sai</div>
        </div>

        <div class="my-1">
          <!-- PAX LISTS -->
          <div class="my-1">
            <div
              v-for="pax in paxData"
              :key="pax.paxId"
              class="mb-1"
            >
              <BFormCheckbox v-model="pax.isCheck">
                <div class="font-weight-bolder font-medium-1">
                  {{ pax.paxId }} {{ pax.lastName }} {{ pax.firstName }} {{ pax.title }}
                </div>
              </BFormCheckbox>
              <div class="d-flex-center px-2">
                <BInputGroup
                  v-if="pax.isCheck"
                  :id="`memberFly-${pax.paxId}`"
                  class="border rounded"
                >
                  <BInputGroupPrepend
                    :id="`memberFly-${pax.paxId}`"
                    :class="isMobileView ? 'flex-wrap' : ''"
                  >
                    <v-select
                      :id="`memberFly-${pax.paxId}`"
                      v-model="pax.fFlyerAirline"
                      :options="[]"
                      style="width: 200px; max-height: 38px;"
                      label="text"
                      class="membership-airline-select"
                      :class="isMobileView ? 'border-secondary' : ''"
                      disabled
                      :clearable="false"
                      :placeholder="`${$t('flight.airline')}`"
                      :reduce="val => val.value"
                    >
                      <template #option="data">
                        <span class="font-small-3 my-25 font-weight-bold">
                          {{ data.text }}
                        </span>
                      </template>
                      <template #selected-option="data">
                        <span
                          class="font-small-3 font-weight-bolder text-nowrap text-truncate"
                          style="max-width: 200px;"
                        >
                          {{ data.text }}
                        </span>
                      </template>
                    </v-select>
                  </BInputGroupPrepend>

                  <b-form-input
                    :id="`cardNumber-${pax.paxId}`"
                    v-model="pax.fFlyerNumber"
                    :class="isMobileView ? 'border-secondary' : ''"
                    type="number"
                    style="min-width: 145px"
                    :placeholder="`${$t('flight.memberFly')}`"
                  />
                </BInputGroup>
              </div>
            </div>
          </div>
          <BAlert
            v-if="errorMessage"
            show
            variant="danger"
            class="px-2 py-50"
          >
            {{ errorMessage }}
          </BAlert>
        </div>

        <BAlert
          show
          variant="warning"
          class="px-2 py-50"
        >
          Lưu ý: Kiểm tra lại thông tin thẻ Bông Sen Vàng khi thêm thành công hiển thị dưới thông tin hành khách.
        </BAlert>
      </IAmOverlay>

      <template #modal-footer="{ cancel }">
        <div class="d-flex-center gap-2">
          <BButton
            variant="flat-dark"
            @click="cancel()"
          >
            {{ $t('close') }}
          </BButton>

          <BButton
            variant="info"
            :disabled="loading"
            @click="handleSubmit"
          >
            {{ $t('reservation.addBSV') }}
          </BButton>
        </div>
      </template>
    </BModal>

    <!-- ANCHOR Show response -->
    <BModal
      id="modal-add-multi-fftv-show-response"
      :title="$t('reservation.addBSV')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-75"
      centered
      size="lg"
    >
      <template #modal-footer="{close}">
        <b-button
          variant="flat-dark"
          class="rounded-pill px-2"
          @click="close"
        >
          {{ $t('reservation.close') }}
        </b-button>
      </template>

      <IAmOverlay :loading="!response">
        <div
          v-if="response"
          class="position-relative"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-secondary"
            class="position-absolute p-75 rounded-circle m-25"
            style="top: 0; right: 10px; z-index: 9999;"
            :disabled="disabledCopy"
            @click="handleCopyText"
          >
            <feather-icon
              v-b-tooltip.hover.window
              icon="CopyIcon"
              size="21"
              class="text-dark"
              :title="$t('copy')"
            />
          </b-button>

          <template v-if="!isEmpty(response.booking)">
            <b-form-textarea
              :value="response.booking.join('\n')"
              class="font-weight-bolder text-uppercase"
              rows="16"
              max-rows="40"
              style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif; height: 60vh; overflow: auto;"
            />
          </template>

          <BAlert
            show
            variant="danger"
            class="px-1 py-50 mt-1"
          >
            Vui lòng kiểm tra lại thông tin hành khách.
          </BAlert>
        </div>
      </IAmOverlay>
    </BModal>
  </div>
</template>
<script>
import {
  BModal, BFormCheckbox, BFormInput, BInputGroup, BInputGroupPrepend, BButton, BAlert, VBTooltip, BFormTextarea,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import { useClipboard } from '@vueuse/core'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BAlert,
    BFormTextarea,
    vSelect: () => import('vue-select'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    bookingCode: { type: String, default: '' },
    source: { type: String, default: '' },
    paxLists: { type: Array, default: () => [] },
    defaultPhoneNumber: { type: String, default: '' },
    defaultEmailAddress: { type: String, default: '' },
  },
  setup(props) {
    const { addMultiFftv, loading } = useReservationHandle()
    const isRemoveEmail = ref(false)
    const isRemovePhoneNumber = ref(false)
    const emailAddressReplace = ref('')
    const phoneNumberReplace = ref('')
    const paxData = ref([])
    const errorMessage = ref('')
    const response = ref()
    function showHandle() {
      isRemoveEmail.value = false
      isRemovePhoneNumber.value = false
      errorMessage.value = ''
      phoneNumberReplace.value = props.defaultPhoneNumber
      emailAddressReplace.value = props.defaultEmailAddress

      paxData.value = props.paxLists.filter(pax => pax.paxType !== 'INFANT').map(pax => ({
        paxId: pax.paxId,
        firstName: pax.firstName,
        lastName: pax.lastName,
        title: pax.title,
        fFlyerNumber: pax.fFlyerNumber,
        fFlyerAirline: 'Vietnam Airlines',
        isCheck: true,
      }))
    }

    async function handleSubmit() {
      errorMessage.value = ''
      const paxInfo = paxData.value.filter(pax => pax.isCheck && pax.fFlyerNumber).map(pax => ({
        firstName: pax.firstName,
        lastName: pax.lastName,
        title: pax.title,
        fFlyerNumber: pax.fFlyerNumber,
      }))
      if (!paxInfo.length) {
        errorMessage.value = 'Vui lòng nhập số thẻ thành viên của hành khách'
        return
      }
      const payload = {
        source: props.source,
        pnrNumber: props.bookingCode,
        emailAddress: isRemoveEmail.value ? emailAddressReplace.value : undefined,
        phoneNumber: isRemovePhoneNumber.value ? phoneNumberReplace.value : undefined,
        paxInfo,
      }

      response.value = await addMultiFftv(payload)
      this.$bvModal.hide('modal-add-multi-fftv')
      this.$bvModal.show('modal-add-multi-fftv-show-response')
    }

    // Response
    const disabledCopy = ref(false)
    const { copy } = useClipboard()

    function handleCopyText() {
      if (!response.value || (response.value && isEmpty(response.value.booking))) return

      disabledCopy.value = true

      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      const textValue = response.value.booking.join('\n')
      copy(textValue)
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }
    return {
      showHandle,
      isRemoveEmail,
      isRemovePhoneNumber,
      phoneNumberReplace,
      paxData,
      handleSubmit,
      emailAddressReplace,
      loading,
      errorMessage,
      disabledCopy,
      handleCopyText,
      response,
      isEmpty,
    }
  },
}
</script>
<style lang="scss" scoped>

.membership-airline-select ::v-deep {
  .vs__dropdown-toggle {
    height: 38px !important;
    border: 0px !important;
    padding-bottom: 3px !important;
     .vs__open-indicator {
      margin-top: 0 !important;
   }
  }

  ul.vs__dropdown-menu {
    min-width: 300px !important;
  }
}
</style>
